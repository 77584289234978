import * as constants from "../consts/index";

import { Hero } from "../components/components/hero/TwoColumnWithVideoForContact";
import Layout from "../components/layout";
import React from "react";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import tw from "twin.macro";
import Img from "gatsby-image";
import { ReactComponent as StarIcon } from "../../node_modules/feather-icons/dist/icons/star.svg";

const SectionHeading = tw.h2`text-4xl font-bold tracking-tight`;
// const SectionContainer = tw.div`max-w-3xl px-5 py-12 mx-auto flex flex-wrap`
const ParagraphText = tw.p`text-gray-700 mt-4 font-medium leading-relaxed`;

const DescriptionContainer = tw.div`grid gap-3 row-gap-3 lg:grid-cols-3 md:divide-x`;
const UnorderedList = tw.ul`space-y-3 px-4`;
const ListItem = tw.li`flex items-center`;
const ListItemIconContainer = tw.span`mr-2`;
const ListItemTitle = tw.span`font-bold text-2xl text-blue-800`;
const ListItemDescription = tw.li`flex items-start`;
const ListItemDescriptionText = tw.span`leading-relaxed text-gray-700 text-lg ml-8`;

function AboutPage({ data }) {
  return (
    <Layout
      bgColor={constants.bgColor}
      isDark={constants.isDark}
      Hero={
        <Hero
          bgColor={constants.bgColor}
          heading={<>About Us</>}
          description={"Our innovative and tasty spices, chiles, herbs, salts, and ingredients can help you in your kitchen. We are here to offer you some special tastes, whether you are an amateur home cook, a weekend grill, or a professional chef."}
          isDark={constants.isDark}
          imageSrc={data.file.childImageSharp.fluid}
          imageCss={"rounded-2xl shadow-xl z-10"}
          imageDecoratorBlob={false}
        />
      }
    >
      <SEO
        keywords={[
          `about us`,
          `about`,
          `spice`,
          `spice supplier`,
          `sinaran rempah`,
          `spices`,
          `spices malaysia`,
        ]}
        title="About Us"
      />
      <div className="">
        <div className="container  px-5 pt-8 pb-4 mx-auto flex items-center sm:flex-no-wrap flex-wrap">
          <div className="w-full md:w-1/2 overflow-hidden p-10 relative">
            <p className="text-lg text-green-500 font-sans font-semibold">
              Brief History
            </p>
            <SectionHeading>Our Story</SectionHeading>
            <ParagraphText>
              {
                /* Our story began in 1971, where we started out our manufacturing
              and spices supply business from a double story house. It&apos;s
              true and we don&apos;t tell lies.
              <br />
              We started out as &apos;Tian Lee&apos; (天利), as it&apos;s
              actually the name of our founder. As time goes on, our business
              grew and we were able to expand to a huge factory.
              <br /> By then, we changed our name to{' '}
              <strong>Sinaran Rempah Asli</strong> to fit ourself into the
              Malaysian market. */
              }
              It was in 1971 when we first started our business. We started the
              spices supply business from a double-story house. We started as
              &quot;Tian Lee&quot; (天利) behind the name of our founder. With
              time, our business grew, and we made a huge factory. After that,
              we changed our name to{" "}
              <strong>Sinaran Rempah Asli Sdn. Bhd.</strong>
              to make our place and name in the Malaysian spice market.
            </ParagraphText>
            <SectionHeading></SectionHeading>
            <ParagraphText>
              {/* This one is dunno when */}
              {
                /* We started Sinaran to serve the domestic market with our wide
              variety and freshest spices. We are certified by MeSTI and HALAL
              by JAKIM Malaysia, and we are always committed to adhere to the
              standard to deliver HALAL products. With our suppliers from India,
              Africa and Indonesia, rest assured we are able to supply the best
              quality and freshest spices into the Malaysian market. */
              }
              {/* Latest code below 2021/05/19 */}
              We started to serve the domestic market with a huge variety of
              pure and fresh spices. Our suppliers are in India, Africa, and
              Indonesia, and they supply our products in the Malaysia market. We
              are proudly certified by MeSTI and HALAL by JAKIM Malaysia for
              producing quality products that are rendered as halal according to
              the Malaysian halal standards.
              {
                /*
              We started to serve the domestic market with a huge variety of
              pure and fresh spices. Our suppliers are from India, Africa, and
              Indonesia, and we supply our products in the Malaysia market. We
              have committed to producing the best-quality food products in
              Malaysia.
      */
              }
            </ParagraphText>
            <div className="grid gap-8 row-gap-8 sm:grid-cols-2 mt-4 mx-auto">
              <div>
                <div className="flex items-center justify-center w-auto h-auto mb-4">
                  <Img fixed={data.halalLogo.childImageSharp.fixed} />
                </div>
              </div>
              <div>
                <div className="flex items-center justify-center w-auto h-auto mb-4">
                  <Img fixed={data.mestiLogo.childImageSharp.fixed} />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 overflow-hidden p-2 relative h-1/6">
            <div className="p-2">
              <Img
                className="rounded-lg "
                fluid={data.sinaranImage.childImageSharp.fluid}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="pb-8">
        <div className="container border-t border-t-gray-200 px-5 pt-8 pb-4 mx-auto flex items-center sm:flex-no-wrap flex-wrap">
          <div className="w-full md:w-1/2 overflow-hidden p-2 relative">
            <div className="w-auto mx-auto p-2">
              <Img
                className="rounded-lg "
                fluid={data.sinaranLogo.childImageSharp.fluid}
              />
            </div>
          </div>
          <div className=" w-full md:w-1/2 overflow-hidden p-10 relative">
            <p className="text-lg text-green-500 font-sans font-semibold">
              What we value
            </p>
            <SectionHeading>Our Value</SectionHeading>
            <p className="mt-4 text-gray-700 font-medium leading-relaxed">
              We take our values very{" "}
              <strong>seriously</strong>. We believe in the value that says{" "}
              <span className="italic">
                &quot;great prices on all of our great products&quot;
              </span>
            </p>
            <div className="mt-8">
              <p className="text-lg text-green-500 font-sans font-semibold">
                Objectives
              </p>
              <SectionHeading>Our Mission</SectionHeading>
              <ParagraphText>
                {
                  /* There&apos;s a notion that to grow a business, you have to be
              rustless. But we know there&apos;s a better way to grow. One where
              what&apos;s good for us and is also good for customers. We believe
              that food is one of the ways to make human happy and it plays an
              import needs. Therefore, we are here to provide you the good
              quality of hundred types of cooking spices for you to present the
              best food for your loved ones. */
                }
                Our mission is to grow our business without compromising the
                quality of our products. When it comes to growing up, we find a
                way that is good for us and our customers. Since we are a food
                company, we understand that food is a basic human need and makes
                others happy. So, we are here to provide you with the best
                cooking spices to add taste to your food.
              </ParagraphText>
            </div>
          </div>
          <div className="container border-t border-t-gray-200 mt-8 px-4 py-16 mx-auto md:px-24 lg:px-8 lg:py-20">
            <p className="text-lg text-green-500 font-sans font-semibold md:pl-2">
              The best in the industry
            </p>
            <h5 className="mb-2 mb-8 text-4xl font-bold leading-none md:pl-2">
              Along the way, we have been
              <br className="hidden md:block" />
              <span className="inline-block text-green-500">
                the best in Malaysia
              </span>
            </h5>
            <DescriptionContainer>
              <UnorderedList>
                <ListItem>
                  <ListItemIconContainer>
                    <StarIcon className="text-blue-800" />
                  </ListItemIconContainer>
                  <ListItemTitle>
                    Our Quality
                  </ListItemTitle>
                </ListItem>
                <ListItemDescription>
                  <ListItemDescriptionText>
                    It has been about 50 years ago since we served our
                    first-ever spice range. Since that day, we have kept the
                    quality of the spices ever increasing. We believe in
                    producing spices and herbs with uncompromised quality. We
                    have set rigorous standards for collecting herbs and
                    manufacturing spices to provide you with pure and fresh
                    products. The best quality premium products have made us
                    stand out among our competitors.
                  </ListItemDescriptionText>
                </ListItemDescription>
              </UnorderedList>
              <UnorderedList>
                <ListItem>
                  <ListItemIconContainer>
                    <StarIcon className="text-blue-800" />
                  </ListItemIconContainer>
                  <ListItemTitle>
                    Our Expertise
                  </ListItemTitle>
                </ListItem>
                <ListItemDescription>
                  <ListItemDescriptionText>
                    We believe that honesty is the best policy. All our dealings
                    are based on integrity and honesty. There is no use of any
                    impurity when it comes to the eatables. We have always done
                    everything with integrity, whether it&apos;s sourcing or
                    manufacturing, or dealing with our partners. Many kitchen
                    experts trust us with their food, and we have earned our
                    place in my hearts with our honest and integrated dealings.
                  </ListItemDescriptionText>
                </ListItemDescription>
              </UnorderedList>
              <UnorderedList>
                <ListItem>
                  <ListItemIconContainer>
                    <StarIcon className="text-blue-800" />
                  </ListItemIconContainer>
                  <ListItemTitle>
                    Our Integrity
                  </ListItemTitle>
                </ListItem>
                <ListItemDescription>
                  <ListItemDescriptionText>
                    What makes us stand out in the market is we have learned
                    from our experiences. We have expertise in our field and
                    work. The decades of experience and hard work have made us
                    where we stand today. We make sure that we have good
                    relations with the global spic industry and our standards
                    match theirs. We have gained detailed knowledge about every
                    minor detail of spice manufacturing. It enables us to serve
                    you and gain the trust of our customers. Our experts work
                    wholeheartedly to get the best and purest spices and herbs
                    for our customers.
                  </ListItemDescriptionText>
                </ListItemDescription>
              </UnorderedList>
            </DescriptionContainer>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AboutPage;

export const query = graphql`
  query {
    file(relativePath: { eq: "bg.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920, maxHeight: 432) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    halalLogo: file(relativePath: { eq: "halal-logo.png" }) {
      childImageSharp {
        fixed(quality: 90, width: 70) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    mestiLogo: file(relativePath: { eq: "mesti-logo.png" }) {
      childImageSharp {
        fixed(quality: 90, width: 130) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    sinaranImage: file(relativePath: { eq: "sinaran-img-front-2.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 800, maxHeight: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sinaranLogo: file(relativePath: { eq: "sinaran-img-front.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
